// init nav object from dom
var nav = $('.pageNavbar');
// get heigth of the nav
var navHeight = nav.outerHeight();

// click-trigger
$('a[href*="#"]:not([href="#"])').click(function(event) {
	scrollToSection(this);
	event.preventDefault();
});

// get target position and scrolls to it
function scrollToSection(self) {
	// get the target href
	var href = $(self).attr('href');

	// get the target position
	var targetPos = $(href).offset().top - navHeight + 5;

	// scroll to target
	$('html, body').animate({
		scrollTop: targetPos
	}, 400);
}

// fixed navbar handler
var lastScrollTop = 0; // reset

$(window).scroll(function(event){
	var st = $(this).scrollTop();

	if ((st > lastScrollTop) && (st > 0)) { // when scroll down (hide)
		nav.css('top', -navHeight - 60);
	} else { // when scroll up (show) 
		nav.css('top', 0);
	}

	lastScrollTop = st;
});
