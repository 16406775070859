// --- Config --- //
var purecookieTitle = ""; // Title
var purecookieDesc = "Tato webová stránka používá soubory Cookie. Používáním webové stránky vyjadřujete svůj souhlas s&nbsp;používáním souborů Cookie."; // Description
var purecookieLink = 'Více informací'; // Cookiepolicy link
var purecookieButton = "Souhlasím"; // Button text

function pureFadeIn(el, display) {
	var el = document.getElementById(el);
	el.style.opacity = 0;
	el.style.display = display || "block";

	(function fade() {
		var val = parseFloat(el.style.opacity);
		if (!((val += .02) > 1)) {
			el.style.opacity = val;
			requestAnimationFrame(fade);
		}
	})();
};

function pureFadeOut(el) {
	var el = document.getElementById(el);
	el.style.opacity = 1;

	(function fade() {
		if ((el.style.opacity -= .02) < 0) {
			el.style.display = "none";
		} else {
			requestAnimationFrame(fade);
		}
	})();
};

function setCookie(name, value, days) {
	var expires = "";
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = "; expires=" + date.toUTCString();
	}
	document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
}

function eraseCookie(name) {
	document.cookie = name + '=; Max-Age=-99999999;';
}

function cookieConsent() {
	if (!getCookie('purecookieDismiss')) {
		document.getElementById("cookieBar").innerHTML += '<div class="cookieConsentContainer" id="cookieConsentContainer"><div class="cookieTitle"><a>' + purecookieTitle + '</a></div><div class="cookieDesc"><p>' + purecookieDesc + '</p></div><div class="d-flex justify-content-center justify-content-md-start align-items-center pt-1 pt-md-0 mt-2"><div class="cookieButton"><a onClick="purecookieDismiss();" class="btn btn-sm btn-secondary">' + purecookieButton + '</a></div><small>' + '<a href="https://policies.google.com/technologies/cookies" target="_blank" class="cookieLink smaller text-nowrap ml-4">' + purecookieLink + '</a>' + '</small></div></div>';

		pureFadeIn("cookieConsentContainer");
	}
}

function purecookieDismiss() {
	setCookie('purecookieDismiss', '1', 7);
	pureFadeOut("cookieConsentContainer");
}

document.addEventListener("DOMContentLoaded", function(){
	cookieConsent();
});
